import Vue from "vue";
import App from "./App.vue";
import "@/styles/index.less";
import "normalize.css/normalize.css";
import "nprogress/nprogress.css";
import 'swiper/swiper-bundle.css';
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import router from "./permission";
import VueLazyload from "vue-lazyload";
Vue.use(ElementUI, { size: 'small' });
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 2,
  loading: require("@/assets/images/loading.jpeg"), //加载中图片，一定要有，不然会一直重复加载占位图
  error: require("@/assets/images/error.png"), //加载失败图片
});
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
