import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
/**
 * 避免冗余导航到当前位置：“ /”
 * error： Avoided redundant navigation to current location: "/"
 */
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = [
  {
    path: "/",
    name: "home",
    pageName: "首页",
    component: (resolve) => require(["@/views/home"], resolve),
  },
  {
    path: "/product-traceability",
    name: "productTraceability",
    pageName: "产品溯源平台",
    component: (resolve) => require(["@/views/productTraceability"], resolve),
  },
  {
    path: "/electronic-data",
    name: "electronicData",
    pageName: "首营电子资料交换平台",
    component: (resolve) => require(["@/views/electronicData"], resolve),
  },
  {
    path: "/smart-pharmaceutical-factory",
    name: "smartPharmaceuticalFactory",
    pageName: "智慧药厂大数据管理平台",
    component: (resolve) =>
      require(["@/views/smartPharmaceuticalFactory"], resolve),
  },
  {
    path: "/smart-medicine-warehouse",
    name: "smartMedicineWarehouse",
    pageName: "智能医药仓储管理平台",
    component: (resolve) =>
      require(["@/views/smartMedicineWarehouse"], resolve),
  },
  {
    path: "/smart-retail",
    name: "smartRetail",
    pageName: "九药云零售药店管理系统",
    component: (resolve) => require(["@/views/smartRetail"], resolve),
  },
  {
    path: "/cloud-hospital",
    name: "cloudHospital",
    pageName: "九医云医院",
    component: (resolve) => require(["@/views/cloudHospital"], resolve),
  },
  {
    path: "/about-us",
    name: "aboutUs",
    pageName: "关于我们",
    component: (resolve) => require(["@/views/aboutUs"], resolve),
  },
  {
    path: "/drug-info",
    name: "drugInfo",
    pageName: "药品信息",
    redirect: "/drug-info/first",
    component: (resolve) => require(["@/views/drugInfo"], resolve),
    children: [
      {
        path: "first",
        name: "first",
        pageName: "商品首页",
        component: (resolve) => require(["@/views/drugInfo/first"], resolve),
      },
      {
        path: "drugDetails",
        name: "drugDetails",
        pageName: "商品详情",
        component: (resolve) => require(["@/views/drugInfo/drugDetails"], resolve),
      },
      {
        path: "list/:typeName",
        name: "list",
        pageName: "商品列表",
        component: (resolve) => require(["@/views/drugInfo/drugList"], resolve),
      }
    ]
  },
  {
    path: "/settlement",
    name: "settlement",
    pageName: "入驻审核规范",
    component: (resolve) => require(["@/views/settlement"], resolve),
  },
  {
    path: "/mall",
    name: "mall",
    pageName: "药品商城",
    component: (resolve) => require(["@/views/mall"], resolve),
  },
  {
    path: "/login",
    name: "login",
    pageName: "登录",
    component: (resolve) => require(["@/views/login"], resolve),
  },
];

// 向外部跑出router
export default new Router({
  // mode: "history", //后端支持可开
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: router,
});
